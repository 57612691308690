import React from "react";
import { Container, Navbar as NavbarBs } from "react-bootstrap";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import classes from '../../App.module.css';

export const Navbar: React.FC = (): JSX.Element => {

  const {trackEvent} = useGoogleAnalytics();

  const handleNavbarClick = () => {
    trackEvent('Link', 'Click', 'Logo link in Navbar');
  }

  return (
    <NavbarBs className="bg-white shadow-sm mb-2">
      <Container>
        <NavbarBs.Brand href="/" onClick={handleNavbarClick} className={classes['brand-name']}>Bonzer Care</NavbarBs.Brand>
      </Container>
    </NavbarBs>
  );
};
