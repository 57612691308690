import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { clarity } from "react-microsoft-clarity";
import { GoogleAnalyticsProvider } from "./providers/GoogleAnalyticsProvider";
import { Home } from "./pages/Home";
import { Layout } from "./pages/Layout";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ScrollToTop } from "./utilities/ScrollToTop";

const App = () => {
  clarity.init("oewkvmnrit");

  //Identify a user
  if (clarity.hasStarted()) {
    clarity.identify("USER_ID", { userProperty: "value" });
  }

  return (
    <Router>
      <ScrollToTop>
        <GoogleAnalyticsProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Route>
          </Routes>
        </GoogleAnalyticsProvider>
      </ScrollToTop>
    </Router>
  );
};
export default App;
