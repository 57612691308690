import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import classes from "./Footer.module.css";

const facebookUrl = "https://www.facebook.com/profile.php?id=61563221038709";
const xURL = "https://x.com/BonzerCare";
const instagramUrl = "https://www.instagram.com/bonzercare/";
const linkedInUrl = "https://www.linkedin.com/in/bonzercare/";

export const Footer: React.FC = (): JSX.Element => {
  const { trackEvent } = useGoogleAnalytics();

  const handleFacebookLinkClick = () => {
    trackEvent("Link", "Click", "Facebook link in footer");
  };

  const handleXlinkClick = () => {
    trackEvent("Link", "Click", "X link in footer");
  };

  const handleInstagramLinkClick = () => {
    trackEvent("Link", "Click", "Instagram link in footer");
  };

  const handleLinkedInLinkClick = () => {
    trackEvent("Link", "Click", "LinkedIn link in footer");
  };

  const handlePrivacyPolicyLinkClick = () => {
    trackEvent("Link", "Click", "Privacy policy link in footer");
  };

  return (
    <footer className={classes.footer}>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col className="text-center mb-4 mt-5">
            <Link
              to={facebookUrl}
              onClick={handleFacebookLinkClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF size={30} className="text-black mx-2" />
            </Link>
            <Link
              to={xURL}
              onClick={handleXlinkClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter size={30} className="text-black mx-2" />
            </Link>
            <Link
              to={instagramUrl}
              onClick={handleInstagramLinkClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={30} className="text-black mx-2" />
            </Link>
            <Link
              to={linkedInUrl}
              onClick={handleLinkedInLinkClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={30} className="text-black mx-2" />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Link to="tel:+12064867475" className="mx-3" >+1 (206) 486-7475</Link>
            <Link to="mailto:admin@bonzercare.com">admin@bonzercare.com</Link>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Link to="/privacy-policy" onClick={handlePrivacyPolicyLinkClick}>
              Privacy Policy
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mb-4 mt-2">&copy; Bonzer Care 2024</Col>
        </Row>
      </Container>
    </footer>
  );
};
