import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import classes from "../App.module.css";

interface PrivacyCardProps {
  fontSize?: string;
  link?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  text: string;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  title?: string;
}

export const PrivacyCard: React.FC<PrivacyCardProps> = ({
  fontSize = "1.25rem",
  link,
  onClick,
  text,
  textAlign = "left",
  title,
}): JSX.Element => {

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
        console.log("Privacy link cliked: ");
        onClick(event);
    }

  };

  return (
    <Card className="mb-3 border-0">
      <Card.Body>
        {title && (
          <Card.Title className={classes.cardTitle} style={{ fontSize }}>
            {title}
          </Card.Title>
        )}
        <Card.Text style={{ textAlign }}>
          {text}
          {link && (
            <Link to={`mailto:${link}`} onClick={handleClick}>
              {link}
            </Link>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
